import React, {Component} from 'react'
import {setUserActive, setUserAuth, setUserData, setUserToken} from "../../../store/user/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";
import {Avatar, Cell, Separator} from "@vkontakte/vkui";
import {Icon28MailOutline, Icon28MoneyHistoryBackwardOutline} from "@vkontakte/icons";

class Credits extends Component {
    render() {
        const user = this.props.user.data

        return (
            user && user.credits.length
                ? user.credits.map((credit, index) => (
                    <React.Fragment key={index}>
                        {index > 0 ? <Separator style={{margin: '5px 0'}}/> : null}
                        <Cell
                            description={credit.percent + '%'}
                            after={(credit.pivot.balance).toLocaleString() + ' / ' + (credit.pivot.summa).toLocaleString() + ' руб.'}
                            before={<Avatar><Icon28MoneyHistoryBackwardOutline/></Avatar>}
                        >{credit.name}</Cell>
                    </React.Fragment>
                ))
                : <Cell
                    disabled={true}
                    description={'Кредиты не найдены'}
                ></Cell>
        )
    }
}

const mapStateToProps = (state) => {
        return {
            user: state.user,
        };
    }
;

const mapDispatchToProps = {
        setUserData,
        setUserAuth,
        setUserToken,
        setUserActive,

    }
;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Credits));