import React, {Component} from 'react'
import {Avatar, Button, Cell, Group, Header, Text, Div} from "@vkontakte/vkui";
// import classes from './NAME.module.scss'
import auto from '../../../img/sledstvennyij.jpeg'

class Screen1 extends Component {
    render() {
        console.log(this.props)
        return (
            <div className={''}>
                <Group header={<Header mode="secondary">Утро. Заведено дело</Header>}>
                    <Div>
                            <img src={auto} style={{width: '290px', display: 'block', margin: '15px 15px 0 0'}} alt=""/>

                        <span >Сегодня утром вы узнали, что на вас было заведено уголовное дело за <strong>мошенничество</strong> в особо крупных размерах.
                            После того, как вы обзвонили всех знакомых адвокатов, поняли, что выиграть суд будет невозможно, и стали думать, что же со всем этим делать..</span>
                    </Div>
                </Group>
            </div>
        )
    }
}

export default Screen1