import React from 'react';
import PropTypes from 'prop-types';

import { Panel, PanelHeader, PanelHeaderBack } from '@vkontakte/vkui';

import persik from '../img/persik.png';
import './Persik.css';
import {PAGE_MAIN, PAGE_PERSIK, router} from "../routers";
import {withRouter} from "@happysanta/router";

const Persik = props => {
	return (
		<Panel id={props.id}>
			<PanelHeader
				left={<PanelHeaderBack onClick={() => router.pushPage(PAGE_MAIN)} />}
			>
				Persik
			</PanelHeader>
			<img className="Persik" src={persik} alt="Persik The Cat"/>
		</Panel>
	)
};

Persik.propTypes = {
	id: PropTypes.string.isRequired,
};
export default withRouter(Persik);
