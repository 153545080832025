import {
	SET_BANK_TRANSFER_TYPE,
	SET_CARD_TRANSFER_DATA,
	SET_CREDIT_TRANSFER_DATA,
	SET_DEPOSIT_TRANSFER_DATA
} from './actionTypes';

export const setCardTransferData = (data) => (
	{
		type: SET_CARD_TRANSFER_DATA,
		payload: data
	}
);

export const setCreditTransferData = (data) => (
	{
		type: SET_CREDIT_TRANSFER_DATA,
		payload: data
	}
);

export const setDepositTransferData = (data) => (
	{
		type: SET_DEPOSIT_TRANSFER_DATA,
		payload: data
	}
);

export const setBankTransferType = (data) => (
	{
		type: SET_BANK_TRANSFER_TYPE,
		payload: data
	}
);
