import React, {Component} from 'react'
import {setUserActive, setUserAuth, setUserData, setUserToken} from "../../../../store/user/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";
import {
    Avatar, Button,
    Card,
    CardScroll,
    Group,
    Header,
    HorizontalCell,
    HorizontalScroll, Link,
    Panel,
    PanelHeader, PanelHeaderBack, SimpleCell, Spacing, Tabs, TabsItem
} from "@vkontakte/vkui";
import {Icon56PaymentCardOutline} from "@vkontakte/icons";
import axios from "../../../../axios/axios";
import {SERVER_CONFIG} from "../../../../config";
import {PAGE_MAIN, router} from "../../../../routers";

class CardAdd extends Component {
    state = {
        cards: [],
        card: null
    }
    orderCard = async () => {
        const request = await axios.post('/card/add', {id: this.state.card.id}, SERVER_CONFIG)
        await router.popPage()
    }

    componentDidMount() {
        axios.get('/card', SERVER_CONFIG)
            .then(res => res.data)
            .then(res => {
                this.setState({
                    cards: res
                })
            })
    }

    render() {

        const user = this.props.user.data

        if (!this.state.card && this.state.cards.length > 0) {
            this.setState({
                card: this.state.cards[0]
            })
        }

        return (
            <Panel id={this.props.id}>
                <PanelHeader left={<PanelHeaderBack onClick={() => router.popPage()} />}>Заказать карту</PanelHeader>
                <Group>
                    <Tabs mode="buttons">
                        <HorizontalScroll>

                            <div style={{display: 'flex'}}>
                                {this.state.cards.length > 0 ? this.state.cards.map((card, index) =>
                                        <TabsItem
                                            key={index}
                                            onClick={() => this.setState({activeTab3: 'news', card})}
                                            selected={this.state.card === card}
                                        >
                                            <HorizontalCell

                                                size='l'
                                                header={card.bank_name + ' ' + card.tariff}
                                                subtitle={card.is_credit ? 'Кредитная' : 'Дебетовая'}
                                                disabled={true}
                                                onClick={() => {
                                                    console.log('select')
                                                    this.setState({
                                                        card
                                                    })
                                                }}
                                            >
                                                <Avatar size={128} mode='image'
                                                ><Icon56PaymentCardOutline/></Avatar>
                                            </HorizontalCell>
                                        </TabsItem>
                                    )

                                    : null
                                }

                            </div>
                        </HorizontalScroll>
                    </Tabs>
                </Group>
                {this.state.card ? <Group>
                    <Header mode="secondary">Информация о карте</Header>

                    <SimpleCell
                        disabled={true}
                        indicator={this.state.card.is_credit ? 'Кредитная' : 'Дебетовая'}
                    >Тип карты</SimpleCell>
                    <SimpleCell
                        disabled={true}
                        style={{color: this.state.card.level > this.props.user.data.level ? 'red' : ''}}
                        indicator={this.state.card.level}
                    >Необходимый уровень</SimpleCell>

                    <SimpleCell
                        disabled={true}
                        indicator={this.state.card.bank_name + ' ' + this.state.card.tariff}
                    >Эмитент</SimpleCell>

                    <SimpleCell
                        disabled={true}
                        indicator={this.state.card.max_transfer.toLocaleString()}
                    >Лимит на переводы</SimpleCell>

                    <SimpleCell
                        disabled={true}
                        indicator={this.state.card.currency}
                    >Валюта</SimpleCell>

                    <SimpleCell
                        disabled={true}
                        indicator={this.state.card.percent}
                    >{!this.state.card.is_credit
                        ? 'Процент на остаток' : 'Процент на задолжность'}</SimpleCell>

                    {this.state.card.is_credit ?
                        <SimpleCell
                            disabled={true}
                            indicator={this.state.card.credit_limit}
                        >Лимит</SimpleCell>
                        : <SimpleCell
                            disabled={true}
                            indicator={this.state.card.overdraft}

                        >Овердрафт</SimpleCell>}

                </Group> : null}
                <Spacing />
                <Button
                    size="l"
                    disabled={this.state.card ? this.props.user.data.level < this.state.card.level : true}
                    onClick={() => this.orderCard()}
                    mode="primary">
                    Заказать
                </Button>
                <Spacing size={70} />

            </Panel>
        )
    }
}


const mapStateToProps = (state) => {
        return {
            user: state.user,
        };
    }
;

const mapDispatchToProps = {
        setUserData,
        setUserAuth,
        setUserToken,
        setUserActive,

    }
;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardAdd));