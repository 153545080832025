import React, {Component} from 'react'
import classes from './Hello.module.scss'
import './Hello.scss'
import {
    Button,
    Panel,
    PanelHeader,
    Div,
    PanelHeaderContent,
    PanelHeaderBack,
    TabbarItem,
    Tabbar, View, Text
} from "@vkontakte/vkui";
import {PAGE_MAIN, router, VIEW_MAIN} from "../../routers";
import {connect} from "react-redux";
import {setUserActive, setUserAuth, setUserData, setUserToken} from "../../store/user/actions";
import axios from "../../axios/axios";
import {APP_NAME, SERVER_CONFIG} from "../../config";
import Screen1 from "./Screen1/Screen1";
import Screen2 from "./Screen2/Screen2";
import Screen3 from "./Screen3/Screen3";
import {Icon28HomeOutline, Icon28Settings, Icon28ShoppingCartOutline, Icon28StatisticsOutline} from "@vkontakte/icons";
import Home from "../Home";
import Persik from "../Persik";

class Hello extends Component {

    state = {
        screen: 0,
        screenList: [],
        history: [0]
    }
    nextScreen = () => {
        console.log('this.state.screen', this.state.screen)
        const screen = this.state.screen + 1 < this.state.screenList.length ? this.state.screen + 1 : this.state.screen
        const history = this.state.history.concat()
        history.push(screen)

        this.setState({
            screen,
            history
        })
    }

    prevScreen = () => {
        const screen = this.state.screen - 1 >= 0 ? this.state.screen - 1 : this.state.screen
        const history = this.state.history.concat()
        history.push(screen)

        this.setState({
            screen,
            history
        })

    }

    componentDidMount() {
        if (this.state.screenList.length === 0) {
            this.setState({
                screenList: [
                    <Screen1/>,
                    <Screen2/>,
                    <Screen3/>,
                ]
            })
        }
    }

    render() {

        console.log(this.state.history)
        return (
            <>
                <Tabbar className='hello-tabbar'>
                    <TabbarItem

                        onClick={
                            this.state.screenList.length !== this.state.screen + 1 ? this.nextScreen : this.props.registerUser
                        }
                    >
                        <Div className={classes.buttons}>
                            {
                                <Button size="m" >
                                    {
                                        this.state.screenList.length !== this.state.screen + 1 ? 'Далее' : 'Играть'
                                    }
                                </Button>
                            }
                        </Div>
                    </TabbarItem>
                </Tabbar>
                <View
                    onSwipeBack={() => this.prevScreen()}
                    id={VIEW_MAIN}
                    activePanel={this.state.screen}
                    history={this.state.history}
                >
                    {
                        this.state.screenList.map((screenElement, index) => (
                            <Panel id={index} key={index}>
                                <PanelHeader
                                    left={this.state.screen > 0 ? (<PanelHeaderBack onClick={this.prevScreen}/>) : null}
                                >
                                    Добро пожаловать в игру {APP_NAME}
                                </PanelHeader>
                                <PanelHeaderContent>
                                    <Div className={classes.Hello}>
                                        {screenElement}
                                    </Div>

                                </PanelHeaderContent>
                            </Panel>
                        ))
                    }
                </View>


            </>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = {
    setUserData,
    setUserAuth,
    setUserToken,
    setUserActive,

};

// export default Top;
export default connect(mapStateToProps, mapDispatchToProps)(Hello);