import {SET_USER_ACTIVE, SET_USER_AUTH, SET_USER_DATA, SET_USER_DEBTS, SET_USER_TOKEN} from './actionTypes';

export const setUserData = (data) => (
	{
		type: SET_USER_DATA,
		payload: data
	}
);

export const setUserAuth = (data) => (
	{
		type: SET_USER_AUTH,
		payload: data
	}
);

export const setUserToken = (data) => (
	{
		type: SET_USER_TOKEN,
		payload: data
	}
);

export const setUserActive = (data) => (
	{
		type: SET_USER_ACTIVE,
		payload: data
	}
);

export const setUserDebts = (data) => (
	{
		type: SET_USER_DEBTS,
		payload: data
	}
);
