import {combineReducers} from "redux";
import {userReducer} from "./user/reducers";
import {topReducer} from "./top/reducers";
import {tapBarReducer} from "./tapBar/reducers";
import {transferReducer} from "./transfer/reducers";
import {snackBarReducer} from "./snackBar/reducers";

export default combineReducers({
	user: userReducer,
	top: topReducer,
	tapBar: tapBarReducer,
	snackBar: snackBarReducer,
	transfer: transferReducer
});
