import React, {Component} from "react";
import {setUserActive, setUserAuth, setUserData, setUserDebts, setUserToken} from "../../store/user/actions";
import {setTapBarData} from "../../store/tapBar/actions";
import {setBankTransferType, setCardTransferData} from "../../store/transfer/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";
import {Group, Panel, PanelHeader, PanelHeaderBack} from "@vkontakte/vkui";
import {router} from "../../routers";
import Cards from "../Bank/Cards/Cards";
import EducationList from "./EducationList";

class Education extends Component {


    render() {
        return (
            <Panel id={this.props.id}>
                <PanelHeader left={<PanelHeaderBack onClick={() => router.popPage()}/>}>Образование</PanelHeader>
                <EducationList/>
            </Panel>

        )
    }
}

const mapStateToProps = (state) => {
        return {
            user: state.user,
            tapBar: state.tapBar,
            transfer: state.transfer
        };
    }
;

const mapDispatchToProps =
    {
        setUserData,
        setUserAuth,
        setUserToken,
        setUserActive,
        setUserDebts,
        setTapBarData,
        setCardTransferData,
        setBankTransferType
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Education));