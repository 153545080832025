import React, {Component} from 'react'
import classes from './Bank.module.scss'
import {setUserActive, setUserAuth, setUserData, setUserToken} from "../../store/user/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";
import {
    Avatar,
    Banner,
    Button,
    Cell,
    Group,
    Header,
    IconButton,
    Link,
    Panel,
    PanelHeader, PanelHeaderBack, Separator,
    SimpleCell
} from "@vkontakte/vkui";
import UserData from "../../components/UserData/UserData";
import UserHomeMenu from "../../components/UserHomeMenu/UserHomeMenu";
import {Icon28AddOutline, Icon28MailOutline, Icon28MessageOutline, Icon28PaymentCardOutline} from '@vkontakte/icons';
import Cards from "./Cards/Cards";
import Deposits from "./Deposits/Deposits";
import Credits from "./Credits/Credits";
import {setTapBarData} from "../../store/tapBar/actions";
import {PAGE_MAIN, router} from "../../routers";
import InDevelop from "../../components/UI/Modal/InDevelop";

class Bank extends Component {
    warningGradient = 'linear-gradient(90deg, #ffb73d 0%, #ffa000 100%)';
    ;
    state = {
        open: false,
        modalHeader: ''
    }
    onCloseInDevModal = function () {
        this.setState({
            open: false
        })
    }
    onOpenInDevModal = function (title) {
        this.setState({
            open: true,
            modalHeader: title
        })
    }
    render() {
        const fetchedUser = this.props.fetchedUser
        const user = this.props.user.data
        const open = this.state?.open ?? false;
        const modalHeader = this.state?.modalHeader ?? '';
        return (
            <Panel  id={this.props.id}

            >
                <InDevelop header={modalHeader} open={open} onClose={this.onCloseInDevModal.bind(this)}/>
                <PanelHeader left={<PanelHeaderBack onClick={() => router.pushPage(PAGE_MAIN)} />}>Банк</PanelHeader>
                {fetchedUser &&
                <>
                    <Group>
                        <Cards/>
                    </Group>
                    <Group
                        onClick={() => {
                            this.onOpenInDevModal('Вклады')
                        }}
                        header={
                            <Header aside={<Link><Icon28AddOutline/></Link>}
                                    mode="secondary">Вклады</Header>
                        }
                    >
                        <Deposits/>
                    </Group>

                    <Group
                        header={<Header aside={<Link><Icon28AddOutline/></Link>}
                                        mode="secondary">Кредиты</Header>
                        }
                        onClick={() => {
                            this.onOpenInDevModal('Кредиты')
                        }}

                    >
                        <Credits/>

                    </Group>
                </>}
                {this.props.snackMoneyBar}

            </Panel>
        )
    }
}


const mapStateToProps = (state) => {
        return {
            user: state.user,
        };
    }
;

const mapDispatchToProps = {
        setUserData,
        setUserAuth,
        setUserToken,
        setUserActive,

    }
;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Bank));