import React, {Component} from 'react'
import {
    Avatar,
    Banner,
    Button, Card, CardScroll,
    Cell,
    Group,
    Header, HorizontalCell,
    HorizontalScroll, InfoRow, Link,
    ModalCard,
    ModalRoot,
    Separator, SimpleCell, Spacing
} from "@vkontakte/vkui";
import {Icon28PaymentCardOutline, Icon56MoneyTransferOutline, Icon56PaymentCardOutline} from "@vkontakte/icons";
import {setUserActive, setUserAuth, setUserData, setUserToken} from "../../../store/user/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";
import axios from "../../../axios/axios";
import {SERVER_CONFIG} from "../../../config";
import {setTapBarData} from "../../../store/tapBar/actions";
import {PAGE_CARD_ADD, PAGE_CARD_TRANSFER, router} from "../../../routers";
import CardShow from "./CardShow/CardShow";
import {setBankTransferType, setCardTransferData} from "../../../store/transfer/actions";

class Cards extends Component {

    state = {
        addCardShow: null,
        cards: [],
        card: null,
        showAll: false,
        showCard: null
    }

    closeModal = () => {
        this.props.setTapBarData(true)
        this.setState({
            addCardShow: null,
            showAll: false
        })
    }

    openModal = async (modal, card = null) => {
        await this.props.setTapBarData(false)
        switch (modal) {
            case 'MODAL_CARD_SHOW':
                await this.setState({
                    showCard: card
                })
                break
        }
        await this.setState({
            addCardShow: modal
        })

    }


    render() {

        const user = this.props.user.data

        if (!this.state.card && this.state.cards.length > 0) {
            this.setState({
                card: this.state.cards[0],
                showAll: false,

            })
        }
        return (

            user ? <>
                <Banner

                    header="Кошелёк"
                    subheader={
                        <>
                            {user.cards.length > 0 ? user.cards.map((card, index) => <React.Fragment key={index}>
                                {index > 0 ? <Separator style={{margin: '5px 0'}}/> : null}
                                <Cell
                                    before={<Avatar><Icon28PaymentCardOutline/></Avatar>}
                                    after={card.pivot.balance.toLocaleString() + (card.is_credit ? (' / ' + card.credit_limit.toLocaleString()) : '') + ' ' + card.currency}
                                    description={'** ' + (card.pivot.number.toString().slice(-4)) + (card.is_credit ? ' • кред.' : '')}
                                    onClick={() => {


                                        this.openModal('MODAL_CARD_SHOW', card)
                                    }}
                                >
                                    {card.bank_name} {card.tariff}
                                </Cell>
                            </React.Fragment>) : <div>Карты не найдены</div>}

                        </>
                    }
                    actions={<Button mode="tertiary"
                                     onClick={() => this.openModal('MODAL_CARD_MONEY_SEND')}
                                     hasHover={false}>Открыть продукт</Button>}
                />

                <ModalRoot
                    activeModal={this.state.addCardShow}
                    onClose={() => this.closeModal}
                >
                    <ModalCard
                        style={{marginBottom: '100px'}}
                        id={'MODAL_CARD_MONEY_SEND'}
                        onClose={() => this.closeModal()}
                        icon={<Icon56MoneyTransferOutline/>}
                        header="Отправляйте деньги друзьям, используя банковскую карту"
                        subheader="Номер карты получателя не нужен — он сам решит, куда зачислить средства."
                        actions={
                            <Button size="l" mode="primary" onClick={() => {
                                router.pushPage(PAGE_CARD_ADD)
                                this.props.setTapBarData(true)
                            }}>
                                Попробовать
                            </Button>
                        }
                    >
                    </ModalCard>

                    <ModalCard
                        style={{marginBottom: '100px'}}
                        id={'MODAL_CARD_SHOW'}
                        onClose={() => this.closeModal()}
                        // icon={<Icon56MoneyTransferOutline/>}
                        header={this.state.showCard ? this.state.showCard.bank_name + ' ' + this.state.showCard.tariff : null}

                        subheader={
                            <CardShow card={this.state.showCard}/>
                        }
                        actions={
                            this.state.showCard ? [
                                    <Button
                                        style={{width: '100%'}}

                                        size="l"
                                        onClick={() => {
                                            this.props.setCardTransferData(this.state.showCard)
                                            this.props.setBankTransferType('take')
                                            this.closeModal()
                                            router.pushPage(PAGE_CARD_TRANSFER)
                                        }}
                                        mode="primary">
                                        Перевод
                                    </Button>
                                    ,<Button
                                    style={{width: '100%'}}
                                    size="l"
                                    onClick={() => {
                                        this.props.setCardTransferData(this.state.showCard)
                                        this.props.setBankTransferType('put')
                                        this.closeModal()
                                        router.pushPage(PAGE_CARD_TRANSFER)
                                    }}
                                    mode="primary">
                                    Пополнить
                                </Button>
                                ]
                             : null
                        }

                    >

                    </ModalCard>

                </ModalRoot>
            </> : null
        )
    }

}

const mapStateToProps = (state) => {
        return {
            user: state.user,
            tapBar: state.tapBar,
            transfer: state.transfer
        };
    }
;

const mapDispatchToProps =
    {
        setUserData,
        setUserAuth,
        setUserToken,
        setUserActive,
        setTapBarData,
        setCardTransferData,
        setBankTransferType
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Cards));