import React, {Component} from "react";
import {setUserActive, setUserAuth, setUserData, setUserDebts, setUserToken} from "../../store/user/actions";
import {setTapBarData} from "../../store/tapBar/actions";
import {setBankTransferType, setCardTransferData} from "../../store/transfer/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";
import {Div} from "@vkontakte/vkui";
import axios from "../../axios/axios";
import SkillItem from "./BusinessItem";
import {SERVER_CONFIG} from "../../config";
import BusinessItem from "./BusinessItem";


class BusinessList extends Component {

    state = {
        businessList: []
    }



    render() {
        const businessList = this.state.businessList
        return (
            businessList.map((item, key) => {
                return (<BusinessItem item={item} key={key}/>)
            })
        )
    }

    componentDidMount() {
      axios.get('business')
            .then(res => res.data)
            .then(res => this.setState({businessList: res}))

    }
}

const mapStateToProps = (state) => {
        return {
            user: state.user,
            tapBar: state.tapBar,
            transfer: state.transfer
        };
    }
;

const mapDispatchToProps =
    {
        setUserData,
        setUserAuth,
        setUserToken,
        setUserActive,
        setUserDebts,
        setTapBarData,
        setCardTransferData,
        setBankTransferType
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessList));