import React, {Component} from 'react'
import {Avatar, Group, Header, SimpleCell} from "@vkontakte/vkui";
import {Icon28PaymentCardOutline} from "@vkontakte/icons";
import {toCardFormat} from "../../../../helpers";

export default class CurrentCards extends Component {
    render() {
        const sendCards = this.props.sendCards
        let show = false
        if (this.props.userPage) {
            show = true
        }
        console.log(sendCards)
        if (!this.props.userPage && sendCards && sendCards.length > 0) {
            show = true
        }
        return (

            show ? <Group>
                    <Header mode="secondary">Карты{this.props.userPage ? ' пользователя' : null}</Header>
                    {
                        sendCards && sendCards.length > 0 ? sendCards.map(
                            (card, index) => (
                                <SimpleCell
                                    key={index}
                                    before={<Avatar mode="image" size={40}><Icon28PaymentCardOutline/></Avatar>}
                                    description={toCardFormat(card.pivot.number)}
                                    onClick={() => {
                                        this.props.setCardSendActual((card.pivot.number))
                                        this.props.closeModal ? this.props.closeModal() : null
                                    }}
                                    // after={<IconButton><Icon28MessageOutline /></IconButton>}
                                >{`${card.bank_name} ${card.tariff}`}</SimpleCell>
                            ))
                            : <SimpleCell
                                disabled={true}
                                // after={<IconButton><Icon28MessageOutline /></IconButton>}
                            >{this.props.userPage ? 'Доступных карт не найдено' : null}</SimpleCell>
                    }
                </Group> : null

        )
    }
}