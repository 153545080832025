import React from "react";
import {Avatar, Snackbar} from "@vkontakte/vkui";
import {Icon16Block} from "@vkontakte/icons";

export default async function SnackBarWarning(title, setSnackBar) {

    const snackMoneyBar = await <Snackbar
        before={<Avatar size={24} style={{ background: 'red' }}><Icon16Block fill="#fff" width={14} height={14} /></Avatar>}
        onClose={() => setSnackBar(null)}
        action="Скрыть"
        duration={1500}
        onActionClick={() => setSnackBar(null )}
        onClick={() => setSnackBar(null)}

    >{title}</Snackbar>
    setSnackBar(snackMoneyBar)
}