import React, {Component} from 'react'
import classes from './User.module.scss'
import {Group, Panel, PanelHeader, PanelHeaderBack} from "@vkontakte/vkui";
import UserData from "../../components/UserData/UserData";
import axios from "../../axios/axios";
import {app_id, SERVER_CONFIG} from "../../config";
import bridge from "@vkontakte/vk-bridge";
import {PAGE_MAIN, router} from "../../routers";

export default class User extends Component {
    state = {
        fetchedUser: null
    }
    getUser = async () => {
        try {
            const userId = this.props.params.id
            const response = await axios.get('/user/' + userId, SERVER_CONFIG)
            if (!response.data) {
                return false;
            }
            const token = await bridge.send("VKWebAppGetAuthToken", {"app_id": app_id, "scope": ""});

            const fetchedUser = await bridge.send('VKWebAppCallAPIMethod', {
                'method': 'users.get',
                'request_id': 'getUserData_id' + userId,
                'params': {
                    'user_ids':userId,
                    'fields': 'photo_200',
                    'v': '5.130',
                    'access_token': token.access_token
                }
            });
            const user = fetchedUser.response[0];

            this.setState({
                user: response.data,
                fetchedUser: user
            })

            console.log(user)
        }catch (e) {
            console.log(e)
        }


    }

    render() {


        const user = this.state.user
        const fetchedUser = this.state.fetchedUser
        return (
            <Panel id={this.props.id}>
                <PanelHeader left={<PanelHeaderBack onClick={() => router.pushPage(PAGE_MAIN)} />}>Игрок</PanelHeader>
                {fetchedUser &&
                <Group>
                    <UserData user={user} fetchedUser={fetchedUser} />
                </Group>}
                {this.props.snackMoneyBar}

            </Panel>
        )
    }

    componentDidMount() {
        this.getUser()
        setInterval(() => {
            this.getUser()
        }, 7000)
    }
}