import React, {useState, useEffect, Component} from 'react';

import bridge from '@vkontakte/vk-bridge';
import {
    View,
    ScreenSpinner,
    AdaptivityProvider,
    AppRoot,
    Root,
    Epic,
    Tabbar,
    TabbarItem,
    ConfigProvider, Button, Avatar, Snackbar, Div
} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import axios from './axios/axios'


import Home from './panels/Home';
import Persik from './panels/Persik';
import {useLocation, withRouter} from "@happysanta/router";
import {
    PAGE_HELLO,
    PAGE_MAIN, PAGE_PERSIK, PAGE_TOP, PANEL_BANK, PANEL_BUSINESS, PANEL_CARD_ADD, PANEL_CARD_TRANSFER, PANEL_EDUCATIONS,
    PANEL_HELLO, PANEL_JOBS,
    PANEL_MAIN,
    PANEL_PERSIK, PANEL_SKILLS, PANEL_TOP, PANEL_USER,
    router,
    VIEW_MAIN,
} from "./routers";
import {app_id, SERVER_CONFIG} from "./config";
import Hello from "./panels/Hello/Hello";
import {setUserActive, setUserAuth, setUserData, setUserDebts, setUserToken} from "./store/user/actions";
import {connect} from "react-redux";
import Loader from "./components/UI/Loader/Loader";
import {
    Icon16Block,
    Icon16Done,
    Icon28HomeOutline,
    Icon28Settings,
    Icon28ShoppingCartOutline,
    Icon28StatisticsOutline,
    Icon48AddAwardOutline, Icon96GoodsCollection
} from "@vkontakte/icons";
import MoneyButton from "./components/UI/MonneyButton/MoneyButton";
import Top from "./panels/Top/Top";
import {setTopData} from "./store/top/actions";
import User from "./panels/User/User";
import Bank from "./panels/Bank/Bank";
import {setTapBarData} from "./store/tapBar/actions";
import CardAdd from "./panels/Bank/Cards/CardAdd/CardAdd";
import CardTransfer from "./panels/Bank/BankTransfer/BankTransfer";
import {setBankTransferType, setCardTransferData} from "./store/transfer/actions";
import Skill from "./panels/Skill/Skill";
import Job from "./panels/Job/Job";
import {setSnackBarData} from "./store/snackBar/actions";
import SnackBarWarning from "./components/UI/SnackBar/SnackBarWarning";
import SnackBarSuccess from "./components/UI/SnackBar/SnackBarSuccess";
import Education from "./panels/Education/Education";
import Business from "./panels/Business/Business";

class App extends Component {


    state = {
        activeStory: 'feed',
        history: ['feed'],
        fetchedUser: null,
        popout: null,
        toHello: false,
        moneyClick: false,
        snackMoneyBar: null

    }
    location = this.props.location

    componentDidMount() {

        bridge.subscribe(({detail: {type, data}}) => {
            if (type === 'VKWebAppUpdateConfig') {
                const schemeAttribute = document.createAttribute('scheme');
                schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
                document.body.attributes.setNamedItem(schemeAttribute);
            }
        });

        this.fetchData();
        setInterval(() => {
            this.fetchData()
        }, 5000)

    }

    async fetchData() {
        try {
            let user = {}

            if (this.state.fetchedUser) {
                user = this.state.fetchedUser
            } else {
                if (window.location.search) {
                    user = await bridge.send('VKWebAppGetUserInfo');

                } else {
                    user = {
                        bdate: "18.3",
                        city: {id: 146, title: "Тула"},
                        country: {id: 1, title: "Россия"},
                        first_name: "Евгений",
                        id: 71474452,
                        last_name: "Расторгуев",
                        photo_100: "https://sun1-23.userapi.com/s/v1/ig2/I6eQuMkVc21TkHeRc1ke3pvFt5LCZjXIcUPkUc5Wsy1XS25eQv9wZXgmpmREf11FUEGaGswed8WjqDtUIKCXk75P.jpg?size=200x0&quality=96&crop=78,112,781,781&ava=1",
                        photo_200: "https://sun1-23.userapi.com/s/v1/ig2/I6eQuMkVc21TkHeRc1ke3pvFt5LCZjXIcUPkUc5Wsy1XS25eQv9wZXgmpmREf11FUEGaGswed8WjqDtUIKCXk75P.jpg?size=200x0&quality=96&crop=78,112,781,781&ava=1",
                        photo_max_orig: "https://sun1-23.userapi.com/s/v1/ig2/USHv1CwJRv1MYeUMDUCuYSn3d5kwVj16nSASVYb8to-bX4JUMJNxsQekKzk7FTvvnzWOPla15QOw54zU8HhWYhrZ.jpg?size=0x0&quality=96&crop=31,0,940,1280&ava=1",
                        sex: 2,
                        timezone: 3
                    }
                }
            }



            try {
                let request = await axios.get('/info', SERVER_CONFIG)
                await this.props.setUserAuth(true)
                await this.props.setUserActive(request.data.active)

                await this.props.setUserData(request.data)

                const requestDebts = await axios.get('/debts', SERVER_CONFIG)
                await this.props.setUserDebts(requestDebts.data)

            } catch (e) {
                await this.props.setUserAuth(false)
                // window.location.reload()
                return
            }

            this.setState({
                fetchedUser: user
            });

            await this.getTopUsers();


        }catch (e) {

        }


        return true;
    }

    getTopUsers = async () => {
        const request = await axios.post('/top?group=vk_id', {}, SERVER_CONFIG)
        const data = await request.data

        const idUsers = await data.userIds.join(',')

        const token = await bridge.send("VKWebAppGetAuthToken", {"app_id": app_id, "scope": ""}).catch(error => false);

        const fetchedUsers = await bridge.send('VKWebAppCallAPIMethod', {
            'method': 'users.get',
            'request_id': 'fetchUsers',
            'params': {
                'user_ids': idUsers,
                'fields': 'photo_200',
                'v': '5.130',
                'access_token': token.access_token
            }
        }).catch(error => console.log(error));


        await fetchedUsers.response.forEach( (user, key) => {
             data.users[user.id].first_name = user.first_name
             data.users[user.id].last_name = user.last_name
             data.users[user.id].photo_200 = user.photo_200
        })


        await this.props.setTopData(data)

    }

    registerUser = async () => {
        const request = axios.post('/registration', {id: this.props.user.data.vk_id}, SERVER_CONFIG)
        await router.pushPage(PAGE_MAIN)
        await this.props.setUserActive(true)

    }

    async warningMessage(message) {
        console.warn(message)
    }

    async addUserMoney(event) {
        console.log('123')
        event.preventDefault()
        if (this.state.moneyClick) {
            if (!this.props.snackBar) {
                await SnackBarWarning('Получить деньги можно раз в 5 секунд!', this.props.setSnackBarData.bind(this))

            }
            return this.warningMessage('Получить деньги можно раз в 5 секунд!')
        }

        await this.setState({
            moneyClick: true
        })
        await axios.post('/money?', {}, SERVER_CONFIG)
        await SnackBarSuccess('Зачисленно +10 рублей', this.props.setSnackBarData.bind(this))

        this.fetchData()


        const timeout = await setTimeout(async () => {
            await console.log('+1000')
            await clearTimeout(timeout)
            await this.setState({
                moneyClick: false
            })

        }, 5000)
        await console.log('4')

    }

    render() {
        this.location = this.props.location
        // console.log('snackBar', this.props)
        return (
            <ConfigProvider isWebView={true}>
                <AdaptivityProvider>

                    {this.state.fetchedUser && this.props.user.auth ?
                        <AppRoot>
                            <Epic activeStory={VIEW_MAIN} tabbar=
                                {this.props.user.active
                                    ? <>
                                        <Tabbar
                                            style={ !this.props.tapBar.show ? {display: 'none'} : null }
                                        >
                                            {/*Главная*/}
                                            <TabbarItem
                                                selected={this.location.getPageId() === PAGE_MAIN}
                                                onClick={() => router.pushPage(PAGE_MAIN)}
                                            >
                                                <Icon28HomeOutline/>
                                            </TabbarItem>
                                            {/*ТОП*/}
                                            <TabbarItem
                                                onClick={() => {
                                                    router.pushPage(PAGE_TOP)
                                                }}
                                                selected={this.location.getPageId() === PAGE_TOP}
                                            >
                                                <Icon28StatisticsOutline/>
                                            </TabbarItem>
                                            {/*Бабло*/}
                                            <Div
                                                selected={this.location.getPageId() === PAGE_PERSIK}


                                            >
                                                <MoneyButton onClick={event => this.addUserMoney(event)}/>
                                            </Div>
                                            {/*Магазин*/}
                                            <TabbarItem
                                                selected={this.location.getPageId() === PAGE_PERSIK}
                                            >
                                                <Icon28ShoppingCartOutline/>
                                            </TabbarItem>
                                            {/*Настройки*/}
                                            <TabbarItem
                                                selected={this.location.getPageId() === PAGE_PERSIK}
                                            >
                                                <Icon28Settings/>
                                            </TabbarItem>
                                        </Tabbar>
                                        {this.props.snackBar}
                                        <View
                                            onSwipeBack={() => router.popPage()}
                                            id={VIEW_MAIN}
                                            history={this.location.hasOverlay() ? [] : this.location.getViewHistory(VIEW_MAIN)}
                                            activePanel={this.location.getViewActivePanel(VIEW_MAIN)}
                                        >

                                            <Home
                                                id={PANEL_MAIN}
                                                fetchedUser={this.state.fetchedUser}
                                                // snackMoneyBar={this.state.snackMoneyBar}
                                                checkUser={this.props.user.active}/>
                                            <Top
                                                id={PANEL_TOP}
                                                fetchedUser={this.state.fetchedUser}
                                                // snackMoneyBar={this.state.snackMoneyBar}
                                            />
                                            <User
                                                id={PANEL_USER}
                                                fetchedUser={this.state.fetchedUser}
                                                // snackMoneyBar={this.state.snackMoneyBar}
                                                params={this.location.getParams()}
                                            />
                                            <Bank
                                                id={PANEL_BANK}
                                                fetchedUser={this.state.fetchedUser}
                                                // snackMoneyBar={this.state.snackMoneyBar}
                                                params={this.location.getParams()}
                                            />
                                            <CardAdd
                                                id={PANEL_CARD_ADD}
                                                fetchedUser={this.state.fetchedUser}
                                            />
                                            <CardTransfer
                                                fetchData={this.fetchData}
                                                id={PANEL_CARD_TRANSFER}

                                            />
                                            <Skill
                                                fetchData={this.fetchData}
                                                id={PANEL_SKILLS}
                                            />
                                            <Education
                                                fetchData={this.fetchData}
                                                id={PANEL_EDUCATIONS}
                                            />
                                            <Job
                                                fetchData={this.fetchData}
                                                id={PANEL_JOBS}
                                            />
                                            <Business
                                                fetchData={this.fetchData}
                                                id={PANEL_BUSINESS}
                                            />

                                        </View>
                                    </>
                                    : <Hello id={PANEL_MAIN} registerUser={this.registerUser}/>}
                            >

                            </Epic>


                        </AppRoot>


                        : <Loader height="100vh"/>}

                </AdaptivityProvider>
            </ConfigProvider>
        )
    }


}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        tapBar: state.tapBar,
        snackBar: state.snackBar?.data
        // transfer: state.transfer
    };
};

const mapDispatchToProps = {
    setUserData,
    setUserAuth,
    setUserToken,
    setUserActive,
    setUserDebts,
    setTopData,
    setTapBarData,
    setSnackBarData

    // setCardTransferData,
    // setBankTransferType
};

// export default Top;
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
