import React, {Component} from 'react'
import {Button, Div, Group, Header} from "@vkontakte/vkui";
import bomj from "../../../img/bomj.jpeg";
// import classes from './NAME.module.scss'

class Screen3 extends Component {
    render() {
        return (
            <div className={''}>
                <Group header={<Header mode="secondary">Вечер. Новая жизнь</Header>}>
                    <Div>
                        <img src={bomj} style={{width: '290px', display: 'block', margin: '15px 15px 0 0'}} alt=""/>
                        <span >По прилету в новую страну - все было замечательно, пока вы не вышли из аэропорта. Люди, которых вы обманули - нашли вас, отобрали все ваши деньги, ценные бумаги, избили до полусмерти и после всего этого, вы очнулись только в больнице.
                            Там вас продержали какое то время, вылечили, дали чистую одежду и выписали. В итоге - вы один в чужой стране, без дома, без денег и знакомых, которые смогли бы вам помочь.
                            Приходится рассчитывать только на себя.
                        </span>
                        <br/>
                        <span>У вас начинается новая жизнь и кем вы станете - зависит только от вас!</span>
                        <br/>
                        <br/>
                        <span>Что бы начать игру - нажмите на кнопку <strong>Играть</strong></span>


                    </Div>
                </Group>
            </div>
        )
    }
}

export default Screen3