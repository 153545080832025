import React, {useEffect, useState} from "react";
import CardShow from "../../../panels/Bank/Cards/CardShow/CardShow";
import {Div, ModalCard, ModalRoot} from "@vkontakte/vkui";

export default function InDevelop({header, open, onClose}) {

    const [modalView, setModalView] = useState('')
    useEffect(() => {
        setModalView(open ? 'MODAL_CARD_SHOW_DEV' : '')
        open = false;
    }, [open])
    return (
        <ModalRoot
            activeModal={modalView}
            onClose={() => this.closeModal}
        >
            <ModalCard
                style={{marginBottom: '100px'}}
                id={'MODAL_CARD_SHOW_DEV'}
                onClose={() => {
                    onClose()
                    setModalView('')
                }}
                // icon={<Icon56MoneyTransferOutline/>}
                header={header}

                subheader={
                    <Div>Тут скоро что то будет</Div>
                }

            >

            </ModalCard>
        </ModalRoot>
    )
}