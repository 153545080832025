export const APP_NAME = 'Begin'
export const API_URL = 'https://begin-front-d.techhousepro.ru/api'

export const devKey = '?vk_access_token_settings=&vk_app_id=7889452&vk_are_notifications_enabled=0&vk_is_app_user=1&vk_is_favorite=0&vk_language=ru&vk_platform=desktop_web&vk_ref=other&vk_ts=1625000442&vk_user_id=71474452&sign=QVS_UmVr8FYKCb_4wsVlDRXN1u2Ch5cWu-iXrKXFaYE'
// export const devKey = ''

export const SERVER_CONFIG = {
    headers: {
        Authorization: `Bearer ${window.location.search ? window.location.search : devKey}`
    }
};

export const app_id = 7889452;
