import React, {Component} from 'react'
import classes from './UserData.module.scss'
import {Avatar, Div, Snackbar, Text, Title} from "@vkontakte/vkui";
import {Icon28FavoriteCircleFillYellow} from "@vkontakte/icons";
import appClasses from '../../App.module.scss'

export default class UserData extends Component {

    state = {
        showPremiumPopup: false
    }
    showPremiumPopup = () => {
        console.log('click')
        this.setState({
            showPremiumPopup: !this.state.showPremiumPopup
        })

    }

    render() {
        const fetchedUser = this.props.fetchedUser
        const user = this.props.user
        console.log('user', user)
        const premiumDate = user.premium ? new Date(user.premium_date) : null
        return (
            <div className={classes.UserData}>
                <Div className={classes.AvatarWrapper}>
                    <div className={classes.Avatar}>
                        {fetchedUser.photo_200 ? <Avatar size={96} src={fetchedUser.photo_200}/> : null}
                    </div>
                </Div>

                {user.premium ? <div className={[classes.PremiumMainWrapper]}>
                    <div className={classes.premium_wrapper}>
                        <span onClick={this.showPremiumPopup} className={classes.premium}><Icon28FavoriteCircleFillYellow width={33} height={33} /></span>
                        {
                            this.state.showPremiumPopup ? <div className={classes.premium_popup}>
                                <div>
                                    <div>
                                        <strong>Премиум аккаунт</strong>
                                    </div>
                                    <div>(от {`${premiumDate.getDate() < 10 ? "0" + premiumDate.getDate() : premiumDate.getDate()}.${premiumDate.getMonth() + 1 < 10 ? "0" + (premiumDate.getMonth() + 1) : premiumDate.getMonth() + 1}.${premiumDate.getFullYear()}`})</div>
                                </div>
                                <div onClick={this.showPremiumPopup} className={appClasses.backdrop}></div>
                            </div> : null
                        }
                    </div>
                </div> : null}


                <div className={appClasses.blockCenter}>
                    <Title level={2} weight={'semibold'}>{`${fetchedUser.first_name} ${fetchedUser.last_name}`}</Title>
                </div>
                <div className={appClasses.blockCenter}>
                    <div>
                        <Text>Уровень: <strong>{user.level}</strong></Text>
                        <Text weight={'regular'}>Exp: <strong>{user.exp} / {4 + user.level * 4}</strong></Text>
                        {
                            user.job ? (
                                <>
                                    <Text weight={'regular'}>Работа: <strong>{user.job?.title} </strong></Text>
                                </>
                            ) : null
                        }
                        <br />
                        <Text weight={'regular'}>Баланс: <strong>{user.bank.toLocaleString()} руб.</strong></Text>
                        {

                        }

                    </div>
                </div>
            </div>
        )
    }
}