import {SET_TAP_BAR_DATA} from "./actionTypes";

const initialState = {
	show: true
};

export const tapBarReducer = (state = initialState, action) => {
	state = {...state}
	switch (action.type) {
		case SET_TAP_BAR_DATA:
			state.show = action.payload ? true : false
			break;


	}

	return state;
};
