import React, {Component} from "react";
import {setUserActive, setUserAuth, setUserData, setUserDebts, setUserToken} from "../../store/user/actions";
import {setTapBarData} from "../../store/tapBar/actions";
import {setBankTransferType, setCardTransferData} from "../../store/transfer/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";
import {Button, Div, Group, Text} from "@vkontakte/vkui";
import axios from "../../axios/axios";
import {SERVER_CONFIG} from "../../config";
import {setSnackBarData} from "../../store/snackBar/actions";
import SnackBarSuccess from "../../components/UI/SnackBar/SnackBarSuccess";
import SnackBarWarning from "../../components/UI/SnackBar/SnackBarWarning";


class BusinessItem extends Component {

    state = {
        buttonDisabled: true,
        buttonText: 'Купить'
    }

    async skillAdd() {
        const item = this.props.item
        console.log('item')
        await this.setState({
            buttonDisabled: true,
            buttonText: 'Проверяем ваши документы'
        })
        await axios.post('business/' + item.id, {}, SERVER_CONFIG)
            .then(() => {
                SnackBarSuccess('Вы успешно купили бизнес', this.props.setSnackBarData.bind(this))
                axios.get('info', SERVER_CONFIG)
                    .then(res => res.data)
                    .then(res => {
                        this.props.setUserData(res)
                        this.setState({
                            buttonDisabled: false,
                            buttonText: 'Купить'
                        });
                    })

            })
            .catch(e => {
                this.setState({
                    buttonDisabled: false,
                    buttonText: 'Купить'
                });
                e.response?.data?.message ? SnackBarWarning(e.response?.data?.message, this.props.setSnackBarData.bind(this)) : null
                console.warn(e.response?.data?.message)
            })


    }

    async businessSell() {
        const item = this.props.item
        console.log('item')
        await this.setState({
            buttonDisabled: true,
            buttonText: 'Проверяем бизнес'
        })
        await axios.delete('business/' + item.id, SERVER_CONFIG)
            .then(() => {
                SnackBarSuccess('Вы успешно продали бизнес', this.props.setSnackBarData.bind(this))
                axios.get('info', SERVER_CONFIG)
                    .then(res => res.data)
                    .then(res => {
                        this.props.setUserData(res)
                        this.setState({
                            buttonDisabled: false,
                            buttonText: 'Купить'
                        });
                    })

            })
            .catch(e => {
                this.setState({
                    buttonDisabled: false,
                    buttonText: 'Купить'
                });
                e.response?.data?.message ? SnackBarWarning(e.response?.data?.message, this.props.setSnackBarData.bind(this)) : null
                console.warn(e.response?.data?.message)
            })


    }

    componentDidMount() {
        const item = this.props.item
        const user = this.props.user
        console.log('user.data?.bank >= item.price', user.data?.bank >= item.price)
        console.log('user.data?.skills?.filter(skill => skill.id === item.education_id).length > 0', user.data?.skills?.filter(skill => skill.id === item.education_id).length > 0)
        console.log('!user.data?.business_id', !user.data?.business_id)
        if (user.data?.bank >= item.price && user.data?.educations?.filter(education => education.id === item.education_id).length > 0 && !user.data?.business_id) {
            this.setState({
                buttonDisabled: false
            })
        } else {
            this.setState({
                buttonDisabled: true
            })
        }
    }

    render() {
        const item = this.props.item
        const user = this.props.user
        const business = user?.data?.business_id ?? 0
        return (
            <Group>
                <Div style={{fontSize: 17}}>{item.title}</Div>
                <Div style={{fontSize: 14, paddingTop: 0, paddingBottom: 0, color: '#484848'}}>{item.description}</Div>
                {item.price && item.price > 0 ? (
                    <Div style={{
                        paddingBottom: 0,
                        color: user.data?.bank > item.price ? 'green' : "red"
                    }}>Стоимость: <span style={{fontWeight: 600}}>{item.price}</span></Div>
                ) : null}
                {
                    business > 0 && business === item.id ? (
                        <Div style={{
                            paddingBottom: 0,

                        }}>Цена продажи: <span style={{fontWeight: 600}}>{item.price / 2}</span></Div>
                    ) : null

                }

                {item.education_id && item.education_id > 0 ? (
                    <Div style={{
                        paddingBottom: 0,
                        color: user.data?.educations?.filter(education => education.id === item.education_id).length > 0 ? 'green' : "red"
                    }}>Образование: <span style={{fontWeight: 600}}>{item.educations?.title}</span></Div>
                ) : null}

                {item.level && item.level > 0 ? (
                    <Div style={{
                        paddingBottom: 0,
                        color: user.data?.level >= item.level  ? 'green' : "red"
                    }}>Уровень: <span style={{fontWeight: 600}}>{item.level}</span></Div>
                ) : null}

                <Div style={{paddingBottom: 0}}>Зарплата: {item.salary} руб</Div>
                <Div>
                    {business > 0 && business === item.id ? (
                        <>
                            <Button disabled={true}>Вы уже владеете этим бизнесом</Button>
                            <Button style={{marginLeft: 15}} mode={'commerce'} onClick={this.businessSell.bind(this)} disabled={false}>Продать</Button>
                        </>
                    ) : (
                        <Button disabled={this.state.buttonDisabled}
                                onClick={this.skillAdd.bind(this)}>{this.state.buttonText}</Button>
                    )}

                </Div>
            </Group>

        )
    }

}

const mapStateToProps = (state) => {
        return {
            user: state.user,
            tapBar: state.tapBar,
            transfer: state.transfer,
            snackBar: state.snackBar?.data
        };
    }
;

const mapDispatchToProps =
    {
        setUserData,
        setUserAuth,
        setUserToken,
        setUserActive,
        setUserDebts,
        setTapBarData,
        setCardTransferData,
        setBankTransferType,
        setSnackBarData
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessItem));