import React from "react";
import {Avatar, Snackbar} from "@vkontakte/vkui";
import {Icon16Block, Icon16Done} from "@vkontakte/icons";

export default async function SnackBarSuccess(title, setSnackBar) {

    const snackMoneyBar = await <Snackbar
        before={<Avatar size={24} style={{ background: 'var(--accent)' }}><Icon16Done fill="#fff" width={14} height={14} /></Avatar>}
        onClose={() => setSnackBar(null)}
        onClick={() => setSnackBar(null)}
        action="Скрыть"
        onActionClick={() => setSnackBar(null )}
        duration={1500000}
    >{title}</Snackbar>
    await setSnackBar(null)
    setSnackBar(snackMoneyBar)
}