import React, {Component} from 'react'
import {Button, Div, Group, Header} from "@vkontakte/vkui";
import pobeg from "../../../img/pobeg.jpeg";
// import classes from './NAME.module.scss'

class Screen2 extends Component {
    render() {
        return (
            <div className={''}>
                <Group header={<Header mode="secondary">Обед. Долой все лишнее</Header>}>
                    <Div>
                        <img src={pobeg} style={{width: '290px', display: 'block', margin: '15px 15px 0 0'}} alt=""/>

                        <span >Времени оставалось все меньше и меньше, в любой момент могли приехать из полиции и забрать вас в полицейский участок.
                            Единственный вариант, который остался - <strong>продать все имущество</strong>, снять все деньги, взять свой запасной паспорт с новым именем, купить билеты и улететь в другую страну, что бы начать новую жизнь. Именно так вы и поступили..</span>
                    </Div>
                </Group>
            </div>
        )
    }
}

export default Screen2