import React, {Component} from 'react'
import classes from './UserHomeMenu.module.scss'
import {setUserActive, setUserAuth, setUserData, setUserToken} from "../../store/user/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";
import {CellButton, Header, SimpleCell} from "@vkontakte/vkui";
import {
    Icon28CarOutline,
    Icon28ChefHatOutline, Icon28EducationOutline, Icon28HomeOutline,
    Icon28UserOutline,
    Icon28WalletOutline,
    Icon28WindowCheck,
    Icon28WorkOutline,
    Icon24HammerOutline
} from "@vkontakte/icons";

import {PAGE_BANK, PAGE_BUSINESS, PAGE_EDUCATIONS, PAGE_JOBS, PAGE_SKILLS, router} from "../../routers";

class UserHomeMenu extends Component {
    render() {
        return (
            <div className={classes.UserHomeMenu}>
                <Header mode="secondary">Меню</Header>
                <SimpleCell onClick={() => router.pushPage(PAGE_BANK)} expandable before={<Icon28WalletOutline />}>Банк</SimpleCell>
                <SimpleCell onClick={() => router.pushPage(PAGE_SKILLS)}  expandable before={<Icon24HammerOutline width={28} height={28}  />}>Навыки</SimpleCell>
                <SimpleCell onClick={() => router.pushPage(PAGE_EDUCATIONS)} expandable before={<Icon28EducationOutline />}>Образование</SimpleCell>
                <SimpleCell onClick={() => router.pushPage(PAGE_JOBS)} expandable before={<Icon28WorkOutline />}>Работа</SimpleCell>
                <SimpleCell onClick={() => router.pushPage(PAGE_BUSINESS)} expandable before={<Icon28WindowCheck />}>Бизнес</SimpleCell>
                <SimpleCell onClick={() => this.setState({ activePanel: 'nothing' })} expandable before={<Icon28CarOutline />}>Гараж</SimpleCell>
                <SimpleCell onClick={() => this.setState({ activePanel: 'nothing' })} expandable before={<Icon28HomeOutline />}>Недвижимость</SimpleCell>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = {
    setUserData,
    setUserAuth,
    setUserToken,
    setUserActive,

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserHomeMenu));