import {SET_SNACK_BAR_DATA} from "./actionTypes";

const initialState = {
	data: null
};

export const snackBarReducer = (state = initialState, action) => {
	state = {...state}
	switch (action.type) {
		case SET_SNACK_BAR_DATA:
			state.data = action.payload
			break;


	}

	return state;
};
