import React, {Component} from "react";
import {setUserActive, setUserAuth, setUserData, setUserDebts, setUserToken} from "../../store/user/actions";
import {setTapBarData} from "../../store/tapBar/actions";
import {setBankTransferType, setCardTransferData} from "../../store/transfer/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";
import {Button, Div, Group, Text} from "@vkontakte/vkui";
import axios from "../../axios/axios";
import {SERVER_CONFIG} from "../../config";
import {setSnackBarData} from "../../store/snackBar/actions";
import SnackBarSuccess from "../../components/UI/SnackBar/SnackBarSuccess";
import SnackBarWarning from "../../components/UI/SnackBar/SnackBarWarning";


class JobItem extends Component {

    state = {
        buttonDisabled: true,
        buttonText: 'Устроиться'
    }

    async skillAdd() {
        const item = this.props.item
        console.log('item')
        await this.setState({
            buttonDisabled: true,
            buttonText: 'Проверяем ваши документы'
        })
        await axios.post('job/' + item.id, {}, SERVER_CONFIG)
            .then(() => {
                SnackBarSuccess('Вы успешно устроились', this.props.setSnackBarData.bind(this))
                axios.get('info', SERVER_CONFIG)
                    .then(res => res.data)
                    .then(res => {
                        this.props.setUserData(res)
                        this.setState({
                            buttonDisabled: false,
                            buttonText: 'Устроиться'
                        });
                    })

            })
            .catch(e => {
                this.setState({
                    buttonDisabled: false,
                    buttonText: 'Устроиться'
                });
                e.response?.data?.message ? SnackBarWarning(e.response?.data?.message, this.props.setSnackBarData.bind(this)) : null
                console.warn(e.response?.data?.message)
            })


    }

    componentDidMount() {
        const item = this.props.item
        const user = this.props.user
        if (!item.skill_id || (user.data?.skills?.filter(skill => skill.id === item.skill_id).length > 0 && user.data?.level >= item.level)) {
            this.setState({
                buttonDisabled: false
            })
        } else {
            this.setState({
                buttonDisabled: true
            })
        }
    }

    render() {
        const item = this.props.item
        const user = this.props.user
        const jobs = user?.data?.jobs ?? []
        return (
            <Group>
                <Div style={{fontSize: 17}}>{item.title}</Div>
                <Div style={{fontSize: 14, paddingTop: 0, paddingBottom: 0, color: '#484848'}}>{item.description}</Div>
                {item.skill_id && item.skill_id > 0 ? (
                    <Div style={{
                        paddingBottom: 0,
                        color: user.data?.skills?.filter(skill => skill.id === item.skill_id).length > 0 ? 'green' : "red"
                    }}>Навык: <span style={{fontWeight: 600}}>{item.skills?.title}</span></Div>
                ) : null}

                {item.education_id && item.education_id > 0 ? (
                    <Div style={{
                        paddingBottom: 0,
                        color: user.data?.educations?.filter(education => education.id === item.education_id).length > 0 ? 'green' : "red"
                    }}>Образование: <span style={{fontWeight: 600}}>{item.educations?.title}</span></Div>
                ) : null}

                {item.level && item.level > 0 ? (
                    <Div style={{
                        paddingBottom: 0,
                        color: user.data?.level >= item.level  ? 'green' : "red"
                    }}>Уровень: <span style={{fontWeight: 600}}>{item.level}</span></Div>
                ) : null}

                <Div style={{paddingBottom: 0}}>Зарплата: {item.salary} руб</Div>
                <Div>
                    {jobs?.length > 0 && jobs.filter(job => job.id === item.id).length > 0 ? (
                        <Button disabled={true}>Вы уже тут работаете</Button>
                    ) : (
                        <Button disabled={this.state.buttonDisabled}
                                onClick={this.skillAdd.bind(this)}>{this.state.buttonText}</Button>
                    )}

                </Div>
            </Group>

        )
    }

}

const mapStateToProps = (state) => {
        return {
            user: state.user,
            tapBar: state.tapBar,
            transfer: state.transfer,
            snackBar: state.snackBar?.data
        };
    }
;

const mapDispatchToProps =
    {
        setUserData,
        setUserAuth,
        setUserToken,
        setUserActive,
        setUserDebts,
        setTapBarData,
        setCardTransferData,
        setBankTransferType,
        setSnackBarData
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JobItem));