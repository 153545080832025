import React, {Component} from 'react'
import {setUserActive, setUserAuth, setUserData, setUserToken} from "../../../store/user/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";
import {Avatar, Cell, Separator} from "@vkontakte/vkui";
import {Icon28MailOutline} from "@vkontakte/icons";

class Deposits extends Component {
    render() {
        const user = this.props.user.data

        return (
            user && user.deposits.length
                ? user.deposits.map((deposit, index) => (
                    <React.Fragment key={index}>
                        {index > 0 ? <Separator style={{margin: '5px 0'}}/> : null}
                        <Cell
                            description={deposit.percent + '%'}
                            after={(deposit.pivot.balance).toLocaleString() + ' / ' +(deposit.max_balance).toLocaleString() + ' руб.'}
                            before={<Avatar><Icon28MailOutline/></Avatar>}
                        >{deposit.name}</Cell>
                    </React.Fragment>
                ))
                : <Cell
                    disabled={true}
                    description={'Вклады не найдены'}
                ></Cell>

    )
    }
}

const mapStateToProps = (state) => {
        return {
            user: state.user,
        };
    }
;

const mapDispatchToProps = {
        setUserData,
        setUserAuth,
        setUserToken,
        setUserActive,

    }
;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Deposits));