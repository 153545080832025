import React, {Component} from 'react'
import {
    Group,
    Panel,
    PanelHeader,
    PanelHeaderBack, Tabs, TabsItem
} from "@vkontakte/vkui";
import {router} from "../../../routers";
import {setUserActive, setUserAuth, setUserData, setUserDebts, setUserToken} from "../../../store/user/actions";
import {setTapBarData} from "../../../store/tapBar/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";
import {setBankTransferType, setCardTransferData} from "../../../store/transfer/actions";

import YourSelfTransfer from "./YourSelfTransfer/YourSelfTransfer";
import UsersTransfer from "./UsersTransfer/UsersTransfer";

class BankTransfer extends Component {
    state = {
        updated: false,
        take: {
            card: null,
            credit: null,
            deposit: null,
        },
        activeTab: 'yourself'
    }


    render() {

        const user = this.props.user
        const debts = user.debts;

        return (

            user && debts.length > 0 ?
                <Panel id={this.props.id}>
                    <PanelHeader left={<PanelHeaderBack onClick={() => router.popPage()}/>}>Переводы</PanelHeader>
                    <Group>
                        <Tabs mode="segmented">
                            <TabsItem
                                onClick={() => this.setState({activeTab: 'yourself'})}
                                selected={this.state.activeTab === 'yourself'}
                            >
                                Между счетами
                            </TabsItem>
                            <TabsItem
                                onClick={() => this.setState({activeTab: 'users'})}
                                selected={this.state.activeTab === 'users'}
                            >
                                Другому пользователю
                            </TabsItem>
                        </Tabs>
                    </Group>
                    <Group>
                        {this.state.activeTab === 'yourself' ? <YourSelfTransfer fetchData={this.props.fetchData} />: this.state.activeTab === 'users' ? <UsersTransfer fetchData={this.props.fetchData} /> : null}
                    </Group>
                </Panel>
                : null
        )
    }


}

const mapStateToProps = (state) => {
        return {
            user: state.user,
            tapBar: state.tapBar,
            transfer: state.transfer
        };
    }
;

const mapDispatchToProps =
    {
        setUserData,
        setUserAuth,
        setUserToken,
        setUserActive,
        setUserDebts,
        setTapBarData,
        setCardTransferData,
        setBankTransferType
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BankTransfer));