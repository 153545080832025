import React, {Component} from 'react'
import {
    Button,
    CustomSelect,
    CustomSelectOption,
    Div,
    FormItem,
    FormLayout,
    IconButton,
    Input,
    Spacing
} from "@vkontakte/vkui";
import {
    Icon16Clear, Icon28CoinsOutline,
    Icon28MailOutline,
    Icon28MoneyHistoryBackwardOutline,
    Icon28PaymentCardOutline
} from "@vkontakte/icons";
import axios from "../../../../axios/axios";
import {SERVER_CONFIG} from "../../../../config";
import {PAGE_BANK, router} from "../../../../routers";
import {setUserActive, setUserAuth, setUserData, setUserDebts, setUserToken} from "../../../../store/user/actions";
import {setTapBarData} from "../../../../store/tapBar/actions";
import {setBankTransferType, setCardTransferData} from "../../../../store/transfer/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";

class YourSelfTransfer extends Component {

    state = {
        updated: false,
        take: {
            card: null,
            credit: null,
            deposit: null,
        },

        put: {
            card: null,
            credit: null,
            deposit: null,
        },

        selected: {
            take: 0,
            put: 0
        },
        moneyCount: 0,
        activeTab: 'yourself'
    }

    moneyCountHandler = event => {

        let moneyCount = event.target.value && event.target.value > 0 ? window.parseInt(event.target.value) : 0

        if (moneyCount.toString().length > 15) return
        this.moneyCountSet(moneyCount, true)
        event.target.value = moneyCount
    }

    moneyCountSet(value = null, update = false) {
        if (value === null) {
            value = this.state.moneyCount
        }
        const valueOld = value;
        const take = this.state.selected.take !== null ? window.parseInt(this.state.selected.take) : null;
        let maxValue;
        if (!take) {
            maxValue = this.props.user.data.bank
        } else {
            const debt = this.props.user.debts[take - 1]
            maxValue = debt && debt.pivot ? debt.pivot.balance : 0
        }
        if (value > maxValue) {
            value = (maxValue);
        }
        if (value !== null && (update || value !== valueOld)) {
            this.setState({moneyCount: value})
        }

    }

    transfer = (count = 0) => {
        if (count > 5) {
            return false
        }
        count++
        const take = this.state.selected.take !== null ? window.parseInt(this.state.selected.take) : null;
        const put = this.state.selected.put !== null ? window.parseInt(this.state.selected.put) : null;
        const mainDebt = {
            id: 0,
            type_debt: 'main',
        }

        const debt = {};
        if (!take) {
            debt['take'] = mainDebt
        } else {
            debt['take'] = this.props.user.debts[take - 1]
        }


        if (!put) {
            debt['put'] = mainDebt
        } else {
            debt['put'] = this.props.user.debts[put - 1]
        }

        if (!debt['put'] || !debt['take']) {
            return this.transfer()
        }
        debt['value'] = this.state.moneyCount

        axios.post('/transfer', debt, SERVER_CONFIG)
            .then(res => res.data)
            .then(res => {
                console.log(res)
                router.pushPage(PAGE_BANK)
            })

        console.log(debt)


    }
    prepareDebts = (user, debts) => {
        const debtsActual = debts.map(debt => {

            return ({
                label: (debt.name ? debt.name : debt.bank_name + ' ' + debt.tariff) + ' | ' + debt.pivot.balance.toLocaleString() + ' ' + (debt.currency ? debt.currency : 'руб.'),
                value: debt.debt_id,
                id: debt.debt_id,
                avatar: debt.type_debt === 'card' ? <Icon28PaymentCardOutline/> : debt.type_debt === 'credit' ?
                    <Icon28MoneyHistoryBackwardOutline/> : debt.type_debt === 'deposit' ? <Icon28MailOutline/> : '',
                debt_id: debt.id,
                type_debt: debt.type_debt,
                card_id: debt.card_id ? debt.card_id : null,
                deposit_id: debt.deposit_id ? debt.deposit_id : null,
                credit_id: debt.credit_id ? debt.credit_id : null,
            })
        })
        const debtMain = [{
            label: 'Основной счет | ' + user.data.bank.toLocaleString() + ' руб.',
            value: 0,
            id: 0,
            avatar: <Icon28CoinsOutline/>,
            debt_id: 0,
            type_debt: 'main'
        }]
        const debtsList = debtMain.concat(debtsActual)

        return debtsList;
    }

    selectedHandler = (event, type) => {
        const value = event.target.value
        const state = {...this.state}

        switch (type) {
            case 'take':
                state.selected.take = value
                break
            case 'put':
                state.selected.put = value
                break
        }
        this.setState(state)
        this.moneyCountSet()
    }

    setSelectedFields = (debtsList, selectedTake, selectedPut) => {


        let selectedTakeID = 0;
        let selectedPutID = 0;
        if (selectedTake || selectedPut) {
            for (let i = 0; i < debtsList.length; i++) {
                const el = debtsList[i];
                if (selectedTake === el.type_debt && this.state.take[selectedTake].id === el.debt_id) {
                    selectedTakeID = el.debt_id
                }

                if (selectedPut === el.type_debt && this.state.put[selectedPut].id === el.debt_id) {
                    selectedPutID = el.debt_id
                }
            }
        }
        const state = {...this.state}
        state.updated = true
        if (selectedTakeID >= 0) {
            state.selected.take = selectedTakeID

        }

        if (selectedPutID >= 0) {
            state.selected.put = selectedPutID

        }
        this.setState(state)
    }
    componentDidMount() {
        if (!this.state.updated) {
            const typeTransfer = this.props.transfer.transferType ? this.props.transfer.transferType : null;
            if (typeTransfer === 'take' || typeTransfer === 'put') {
                const state = {...this.state}
                state[typeTransfer] = {
                    card: this.props.transfer.card,
                    credit: this.props.transfer.credit,
                    deposit: this.props.transfer.deposit,
                }
                this.setState(state)
            }
        }
    }
    render() {

        const selectedTake = this.state.take.card ? 'card' : (this.state.take.credit ? 'credit' : (this.state.take.deposit ? 'deposit' : null))
        const selectedPut = this.state.put.card ? 'card' : (this.state.put.credit ? 'credit' : (this.state.put.deposit ? 'deposit' : null))

        const user = this.props.user
        const debts = user.debts;


        const debtsList = this.prepareDebts(user, debts)
        if (selectedTake || selectedPut) {
            if (!this.state.updated) {
                this.setSelectedFields(debtsList, selectedTake, selectedPut)
            }
        }

        let selectedTakeID = this.state.selected.take ? this.state.selected.take : 0;
        let selectedPutID = this.state.selected.put ? this.state.selected.put : 0;
        const textInput = React.createRef();
        const clearField = () => this.setState({moneyCount: 0})

        return (
            <FormLayout>
                <FormItem top="Счет списания">
                    <CustomSelect
                        placeholder="Не выбран"
                        options={
                            // getRandomUsers(10).map(user => ({ label: user.name, value: user.id, avatar: user.photo_100 }))
                            // debts.map(debt => ({ label: (debt.name ? debt.name : debt.bank_name + ' ' + debt.tariff)  + ' | ' + debt.pivot.balance + ' ' + (debt.currency ? debt.currency : 'руб.'), value: debt.debt_id, avatar: user.photo_100 }))
                            debtsList
                        }
                        onChange={event => {
                            this.selectedHandler(event, 'take')
                        }}
                        value={selectedTakeID}

                        renderOption={({option, ...restProps}) => {
                            return (
                                <CustomSelectOption
                                    {...restProps}

                                    before={option.avatar}
                                />
                            )
                        }}
                    />
                </FormItem>
                <FormItem top="Счет зачисления">
                    <CustomSelect
                        placeholder="Не выбран"
                        options={
                            debtsList
                        }
                        value={selectedPutID}
                        onChange={event => {
                            this.selectedHandler(event, 'put')
                        }}
                        renderOption={({option, ...restProps}) => (
                            <CustomSelectOption
                                // selected={this.state.t.id === }
                                {...restProps}

                                before={option.avatar}
                            />
                        )}
                    />
                </FormItem>
                <FormItem top="Сумма">
                    <Input
                        getRef={textInput}
                        pattern="[0-9]*"
                        type="number"
                        value={this.state.moneyCount}
                        onChange={event => this.moneyCountHandler(event)}
                        after={<IconButton hoverMode="opacity" aria-label="Очистить поле"
                                           onClick={clearField}><Icon16Clear/></IconButton>}/>
                </FormItem>
                <Div>
                    <Spacing size={20}/>
                    <Button
                        style={{width: '100%'}}
                        size="l"
                        onClick={() => {
                            this.transfer()
                            this.props.fetchData()
                        }}

                        disabled={(this.state.selected.take === this.state.selected.put) || !((this.state.selected.put !== null && this.state.selected.take !== null && this.state.moneyCount > 0))}
                        mode="primary">
                        Перевести
                    </Button>
                </Div>

                <Spacing size={20}/>
            </FormLayout>
        )
    }
}


const mapStateToProps = (state) => {
        return {
            user: state.user,
            tapBar: state.tapBar,
            transfer: state.transfer
        };
    }
;

const mapDispatchToProps =
    {
        setUserData,
        setUserAuth,
        setUserToken,
        setUserActive,
        setUserDebts,
        setTapBarData,
        setCardTransferData,
        setBankTransferType
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(YourSelfTransfer));