import React, {Component} from 'react'
import classes from './MoneyButton.module.scss'

export default class MoneyButton extends Component {
    render() {

        return (
            <div className={classes.MoneyButton}>
                <div onClick={event => this.props.onClick(event)}>CASH</div>
            </div>
        )
    }
}