import React from "react";
import ReactDOM from "react-dom";
import bridge from "@vkontakte/vk-bridge";
import {ConfigProvider} from '@vkontakte/vkui';
import App from "./App";
import {RouterContext} from '@happysanta/router';
import {router} from "./routers";
import thunk from "redux-thunk";
import rootReducer from './store/reducers';
import {createStore, applyMiddleware} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {Provider} from "react-redux";

export const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(thunk),
));

// Init VK  Mini App
bridge.send("VKWebAppInit");
const application = (
    <Provider store={store}>
        <RouterContext.Provider value={router}>
            <ConfigProvider isWebView={true}>
                <App/>
            </ConfigProvider>
        </RouterContext.Provider>
    </Provider>
)
ReactDOM.render(application, document.getElementById("root"));
if (process.env.NODE_ENV === "development") {
    import("./eruda").then(({default: eruda}) => {
    }); //runtime download
}
