import { Page, Router } from '@happysanta/router';

export const PAGE_MAIN = '/'
export const PAGE_PERSIK = '/persik'
export const PAGE_USER = '/user/:id([0-9]+)';
export const PAGE_HELLO = '/hello';
export const PAGE_TOP = '/top';
export const PAGE_BANK = '/bank';
export const PAGE_CARD_ADD = '/bank/card/add';
export const PAGE_CARD_TRANSFER = '/bank/transfer';
export const PAGE_SKILLS = '/skills';
export const PAGE_EDUCATIONS = '/educations';
export const PAGE_JOBS = '/jobs';
export const PAGE_BUSINESS = '/business';

export const PANEL_MAIN = 'panel_main';
export const PANEL_PERSIK = 'panel_persik';
export const PANEL_USER = 'panel_user';
export const PANEL_HELLO = 'panel_hello';
export const PANEL_TOP = 'panel_top';
export const PANEL_BANK = 'panel_bank';
export const PANEL_CARD_ADD = 'panel_card_add';
export const PANEL_CARD_TRANSFER = 'panel_card_transfer';
export const PANEL_SKILLS = 'panel_skills';
export const PANEL_EDUCATIONS = 'panel_educations';
export const PANEL_JOBS = 'panel_jobs';
export const PANEL_BUSINESS = 'panel_business';

export const VIEW_MAIN = 'view_main';


export const routes = {
    [PAGE_MAIN]: new Page(PANEL_MAIN, VIEW_MAIN),
    [PAGE_PERSIK]: new Page(PANEL_PERSIK, VIEW_MAIN),
    [PAGE_USER]: new Page(PANEL_USER, VIEW_MAIN),
    [PAGE_HELLO]: new Page(PANEL_HELLO, VIEW_MAIN),
    [PAGE_TOP]: new Page(PANEL_TOP, VIEW_MAIN),
    [PAGE_BANK]: new Page(PANEL_BANK, VIEW_MAIN),
    [PAGE_CARD_ADD]: new Page(PANEL_CARD_ADD, VIEW_MAIN),
    [PAGE_CARD_TRANSFER]: new Page(PANEL_CARD_TRANSFER, VIEW_MAIN),
    [PAGE_SKILLS]: new Page(PANEL_SKILLS, VIEW_MAIN),
    [PAGE_EDUCATIONS]: new Page(PANEL_EDUCATIONS, VIEW_MAIN),
    [PAGE_JOBS]: new Page(PANEL_JOBS, VIEW_MAIN),
    [PAGE_BUSINESS]: new Page(PANEL_BUSINESS, VIEW_MAIN),
}

export const router = new Router(routes, {
    defaultPage: PAGE_MAIN,
    enableLogging: true
});

router.start();