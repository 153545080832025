import React, {Component} from 'react'
import {Avatar, Group, Header, HorizontalCell, Panel, SimpleCell} from "@vkontakte/vkui";
import {Icon56PaymentCardOutline} from "@vkontakte/icons";
import {toCardFormat} from "../../../../helpers";

export default class CardShow extends Component {
    render() {
        const card = this.props.card
        return (card ? <>
                <Group>
                    <HorizontalCell

                        size='l'
                        header={card.bank_name + ' ' + card.tariff}
                        subtitle={card.pivot.balance.toLocaleString() + ' ' + card.currency}
                        disabled={true}

                    >
                        <Avatar size={128} mode='image'
                        ><Icon56PaymentCardOutline/></Avatar>
                    </HorizontalCell>
                </Group>
                <Group>
                    <Header mode="secondary">Информация о карте</Header>

                    <SimpleCell
                        disabled={true}
                        indicator={card.is_credit ? 'Кредитная' : 'Дебетовая'}
                    >Тип карты</SimpleCell>

                    <SimpleCell
                        disabled={true}
                        indicator={toCardFormat(card.pivot.number)}
                    >Номер карты</SimpleCell>

                    <SimpleCell
                        disabled={true}
                        indicator={card.max_transfer.toLocaleString()}
                    >Лимит на переводы</SimpleCell>

                    <SimpleCell
                        disabled={true}
                        indicator={card.pivot.max_transfer_today.toLocaleString()}
                    >Доступный лимит</SimpleCell>

                    <SimpleCell
                        disabled={true}
                        indicator={card.currency}
                    >Валюта</SimpleCell>

                    <SimpleCell
                        disabled={true}
                        indicator={card.percent}
                    >{!card.is_credit
                        ? 'Процент на остаток' : 'Процент на задолжность'}</SimpleCell>

                    {card.is_credit ?
                        <SimpleCell
                            disabled={true}
                            indicator={card.credit_limit}
                        >Лимит</SimpleCell>
                        : <SimpleCell
                            disabled={true}
                            indicator={card.overdraft}

                        >Овердрафт</SimpleCell>}

                </Group>
            </> : null
        )

    }

}