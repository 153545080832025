import React, {Component} from 'react'
import classes from './Loader.module.scss'

export default class Loader extends Component {

    render() {
        const styles = {}
        if (this.props.height) {
            styles.height = this.props.height
        }
        return (
            <div className={classes.center} style={styles}>
                <div className={classes.ldsRing}>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
            </div>

        )
    }
}