import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {APP_NAME} from '../config'

import {
    Panel,
    PanelHeader,
    Header,
    Button,
    Group,
    Cell,
    Div,
    Avatar,
    Headline,
    Title,
    Text,
    Card, FormItem, FormLayout, Input
} from '@vkontakte/vkui';
import {PAGE_MAIN, PAGE_PERSIK, router} from "../routers";
import {setUserActive, setUserAuth, setUserData, setUserToken} from "../store/user/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";

import classes from './Home.module.scss'
import {Icon28FavoriteCircleFillYellow} from "@vkontakte/icons";
import UserData from "../components/UserData/UserData";
import UserHomeMenu from "../components/UserHomeMenu/UserHomeMenu";


class Home extends Component {


    render() {

        const fetchedUser = this.props.fetchedUser
        const user = this.props.user.data

        return (
            <Panel id={this.props.id}>
                <PanelHeader >Профиль</PanelHeader>
                {fetchedUser &&
                <>
                    <Group>

                        <UserData user={user} fetchedUser={fetchedUser}/>

                    </Group>
                    <Group>
                        <UserHomeMenu/>
                    </Group>
                </>}
                {this.props.snackMoneyBar}

            </Panel>
        )
    }

}


const mapStateToProps = (state) => {
        return {
            user: state.user,
        };
    }
;

const mapDispatchToProps =
    {
        setUserData,
        setUserAuth,
        setUserToken,
        setUserActive,

    }
;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
