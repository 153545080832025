import {SET_USER_ACTIVE, SET_USER_AUTH, SET_USER_DATA, SET_USER_DEBTS, SET_USER_TOKEN} from "./actionTypes";

const initialState = {
	auth: false,
	token: null,
	active: false,
	data: {},
	debts: []


};

export const userReducer = (state = initialState, action) => {
	state = {...state}
	switch (action.type) {
		case SET_USER_DATA:
			state.data = action.payload
			break;

		case SET_USER_TOKEN:
			state.token = action.payload
			break;

		case SET_USER_AUTH:
			state.auth = action.payload
			break;

		case SET_USER_ACTIVE:
			state.active = action.payload
			break;

		case SET_USER_DEBTS:
			state.debts = action.payload
			break;
	}

	return state;
};
