import React, {Component} from "react";
import {setUserActive, setUserAuth, setUserData, setUserDebts, setUserToken} from "../../store/user/actions";
import {setTapBarData} from "../../store/tapBar/actions";
import {setBankTransferType, setCardTransferData} from "../../store/transfer/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";
import {Button, Div, Group, Text} from "@vkontakte/vkui";
import axios from "../../axios/axios";
import {SERVER_CONFIG} from "../../config";
import SnackBarSuccess from "../../components/UI/SnackBar/SnackBarSuccess";
import {setSnackBarData} from "../../store/snackBar/actions";
import SnackBarWarning from "../../components/UI/SnackBar/SnackBarWarning";


class SkillItem extends Component {

    state = {
        buttonDisabled: true,
        buttonText: 'Обучиться'
    }
    async skillAdd() {
        const item = this.props.item
        console.log('item')
        await this.setState({
            buttonDisabled: true,
            buttonText: 'Идет обучение'
        })
        axios.post('skill/' + item.id, {}, SERVER_CONFIG)
            .then(() => {
                SnackBarSuccess('Вы успешно обучились', this.props.setSnackBarData.bind(this))
                axios.get('info', SERVER_CONFIG)
                    .then(res => res.data)
                    .then(res => {
                        setUserData(res)
                    })
            })
            .catch(e => {
                this.setState({
                    buttonDisabled: false,
                    buttonText: 'Обучиться'
                });
                e.response?.data?.message ? SnackBarWarning(e.response?.data?.message, this.props.setSnackBarData.bind(this)) : null
            })


    }

    componentDidMount() {
        const item = this.props.item
        const user = this.props.user
        if (user.data?.level >= item.level && user.data?.bank >= item.price) {
            this.setState({
                buttonDisabled: false
            })
        }else {
            this.setState({
                buttonDisabled: true
            })
        }
    }

    render() {
        const item = this.props.item
        const user = this.props.user
        const skills = user?.data?.skills ?? []
        return (
            <Group>
                <Div style={{fontSize: 17}}>{item.title}</Div>
                <Div style={{fontSize: 14, paddingTop: 0, paddingBottom: 0, color: '#484848'}}>{item.description}</Div>
                <Div style={{paddingBottom: 0, color: user.data?.level >= item.level ? 'green' : "red"}}>Уровень: <span style={{fontWeight: 600} }>{item.level}</span></Div>
                <Div style={{paddingBottom: 0, color: user.data?.bank >= item.price ? 'green' : "red"}}>Стоимость обучения: {item.price} руб</Div>
                <Div>
                    {skills?.length > 0 && skills.filter(skill => skill.id === item.id).length > 0 ? (
                        <Button disabled={true}>Вы уже обучены</Button>
                    ) : (
                        <Button disabled={this.state.buttonDisabled} onClick={this.skillAdd.bind(this) } >{this.state.buttonText}</Button>
                    ) }

                </Div>
            </Group>

        )
    }

}

const mapStateToProps = (state) => {
        return {
            user: state.user,
            tapBar: state.tapBar,
            transfer: state.transfer
        };
    }
;

const mapDispatchToProps =
    {
        setUserData,
        setUserAuth,
        setUserToken,
        setUserActive,
        setUserDebts,
        setTapBarData,
        setCardTransferData,
        setBankTransferType,
        setSnackBarData
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SkillItem));