import {
	SET_BANK_TRANSFER_TYPE,
	SET_CARD_TRANSFER_DATA,
	SET_CREDIT_TRANSFER_DATA,
	SET_DEPOSIT_TRANSFER_DATA
} from "./actionTypes";

const initialState = {
	card: null,
	credit: null,
	deposit: null,
	transferType: null,
};

export const transferReducer = (state = initialState, action) => {
	state = {...state}
	switch (action.type) {
		case SET_CARD_TRANSFER_DATA:
			state.card = action.payload
			break;

		case SET_CREDIT_TRANSFER_DATA:
			state.credit = action.payload
			break;

		case SET_DEPOSIT_TRANSFER_DATA:
			state.deposit = action.payload
			break;

		case SET_BANK_TRANSFER_TYPE:
			if (action.payload === 'take' || action.payload === 'put') {
				state.transferType = action.payload
			}

			break;


	}

	return state;
};
