import React, {Component} from 'react'
import {setUserActive, setUserAuth, setUserData, setUserDebts, setUserToken} from "../../../../store/user/actions";
import {setTapBarData} from "../../../../store/tapBar/actions";
import {setBankTransferType, setCardTransferData} from "../../../../store/transfer/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";
import {
    Avatar,
    Button, Caption,
    CustomSelect,
    CustomSelectOption,
    Div,
    FormItem,
    FormLayout, Group, Header,
    IconButton,
    Input, ModalCard, ModalRoot, SimpleCell,
    Spacing
} from "@vkontakte/vkui";
import {
    Icon16Clear,
    Icon28MailOutline,
    Icon28MoneyHistoryBackwardOutline,
    Icon28PaymentCardOutline, Icon56MoneyTransferOutline, Icon56PaymentCardOutline
} from "@vkontakte/icons";
import axios from "../../../../axios/axios";
import bridge from "@vkontakte/vk-bridge";
import {app_id, SERVER_CONFIG} from "../../../../config";
import {PAGE_BANK, PAGE_CARD_ADD, PAGE_CARD_TRANSFER, router} from "../../../../routers";
import CardShow from "../../Cards/CardShow/CardShow";
import UserData from "../../../../components/UserData/UserData";
import CurrentCards from "../CurrentCards/CurrentCards";

class UsersTransfer extends Component {

    state = {
        moneyCount: 0,
        selected: {
            take: 0,
            put: '',
            putActual: false,
            putChanged: false
        },
        send: {
            users: null,
            cards: null,
            fetchedUsers: null,
            current: {
                user: null,

                card: null
            }
        },
        timerPutId: null,
        showCardsModal: null,
        userClick: null,
        fetchedUserClick: null,

    }
    selectedTakeHandler = (event) => {
        const value = event.target.value
        const state = {...this.state}
        state.selected.take = value

        this.setState(state)
        this.moneyCountSet()
    }

    setCardSendActual = (cardId) => {
        const state = {...this.state}
        state.selected.put = cardId
        state.selected.putActual = true
        this.setState(state)
    }


    putDebtHandler = (event) => {

        const activeTimer = this.state.timerPutId;
        if (activeTimer !== null) {
            window.clearTimeout(activeTimer)
        }
        const value = event.target.value


        const state = {...this.state}
        state.selected.putActual = false
        state.selected.put = value


        this.setState(state)

        this.moneyCountSet()
        const timerPutId = window.setTimeout(() => {
            event.target.disabled = true
            axios.get('/debt?value=' + value, SERVER_CONFIG)
                .then(res => res.data)
                .then(res => {
                    const idUsers = res.users && res.users.length > 0 ? res.users.map(user => user.vk_id) : []
                    console.log('res', res)

                    bridge.send("VKWebAppGetAuthToken", {"app_id": app_id, "scope": ""})
                        .then(token => {
                            bridge.send('VKWebAppCallAPIMethod', {
                                'method': 'users.get',
                                'request_id': 'fetchUsers',
                                'params': {
                                    'user_ids': idUsers.join(','),
                                    'fields': 'photo_200, photo_50',
                                    'v': '5.130',
                                    'access_token': token.access_token
                                }
                            })
                                .then(res => res.response)
                                .then(fetchedUsers => {
                                    const state = {...this.state}
                                    console.log('state', state)
                                    state.send.fetchedUsers = fetchedUsers
                                    state.send.cards = res.cards
                                    state.send.users = res.users
                                    event.target.disabled = false
                                    event.target.focus()
                                    state.selected.putChanged = true
                                    this.setState(state)
                                    console.log('fetchedUsers', fetchedUsers)
                                })
                                .catch(error => this.putDebtHandler(event));
                        })

                        .catch(error => this.putDebtHandler(event));


                })
                .catch(e => this.putDebtHandler(event))
        }, 1500)

        this.setState({
            timerPutId
        })

    }

    moneyCountHandler = event => {

        let moneyCount = event.target.value && event.target.value > 0 ? window.parseInt(event.target.value) : 0

        if (moneyCount.toString().length > 15) return
        this.moneyCountSet(moneyCount, true)
        event.target.value = moneyCount
    }
    validButton = () => {
        let valid = true
        valid = this.state.selected.putActual
        valid = valid && this.props.user.data.cards[this.state.selected.take].pivot.balance >= this.state.moneyCount

        valid = valid && this.props.user.data.cards[this.state.selected.take].pivot.max_transfer_today >= this.state.moneyCount
        valid = valid &&  this.state.moneyCount > 0
        return valid
    };

    transfer = (event) => {
        event.target.disabled = true
        if (!this.validButton()) {
            event.target.disabled = true
            return false
        }
        console.log(this.state)

        const data = {
            value: this.state.moneyCount,
            card: {
                from: this.props.user.data.cards[this.state.selected.take].pivot.number,
                to: this.state.selected.put,
            }
        }
        axios.post('/transfer/user', data, SERVER_CONFIG)
            .then(res => res.data)
            .then(res => {
                console.log(res)
                router.pushPage(PAGE_BANK)
            })


    }

    moneyCountSet(value = null, update = false) {
        if (value === null) {
            value = this.state.moneyCount
        }
        const valueOld = value;
        const take = this.state.selected.take !== null ? window.parseInt(this.state.selected.take) : null;
        if(take === null) {
            return
        }
        let maxValue;
        const card = this.props.user.data.cards[take]
        maxValue = card && card.pivot ? card.pivot.balance : 0

        if (this.props.user.data.cards[this.state.selected.take].pivot.max_transfer_today < value) {
            value = this.props.user.data.cards[this.state.selected.take].pivot.max_transfer_today
        }

        if (value > maxValue) {
            value = (maxValue);
        }

        if (value !== null && (update || value !== valueOld)) {
            this.setState({moneyCount: value})
        }

    }

    closeModal = () => {
        this.props.setTapBarData(true)
        this.setState({
            showCardsModal: null,
            userClick: null,
            fetchedUserClick: null,
        })
    }

    render() {
        const user = this.props.user
        const cards = user.data.cards;
        const cardList = cards.map((card, index) => {
            return ({
                label: (card.bank_name + ' ' + card.tariff) + ' | ' + card.pivot.balance.toLocaleString() + ' ' + (card.currency ? card.currency : 'руб.'),
                value: index,
                avatar: <Icon28PaymentCardOutline/>,
            })
        })
        const textInput = React.createRef();
        const clearField = () => this.setState({moneyCount: 0})
        const fetchedUsers = this.state.send.fetchedUsers
        const sendCards = this.state.send.cards
        return (
            <>
                <FormLayout>
                    <FormItem
                        top="Счет списания"
                        bottom={'Доступный лимит: ' + this.props.user.data.cards[this.state.selected.take].pivot.max_transfer_today.toLocaleString() + ' / ' + this.props.user.data.cards[this.state.selected.take].max_transfer.toLocaleString() + ' ' + this.props.user.data.cards[this.state.selected.take].currency}
                    >
                        <CustomSelect
                            placeholder="Не выбран"
                            options={cardList}
                            onChange={event => {
                                this.selectedTakeHandler(event)
                            }}
                            value={this.state.selected.take}

                            renderOption={({option, ...restProps}) => {
                                return (
                                    <CustomSelectOption
                                        {...restProps}

                                        before={option.avatar}
                                    />
                                )
                            }}
                        />

                    </FormItem>

                    <FormItem top="Номер карты / ID пользователя">
                        <Input
                            getRef={textInput}
                            pattern="[0-9]*"
                            type="text"
                            value={this.state.selected.put}
                            onChange={event => this.putDebtHandler(event)}
                            after={<IconButton hoverMode="opacity" aria-label="Очистить поле"
                                               onClick={clearField}><Icon16Clear/></IconButton>}/>
                    </FormItem>
                    {
                        this.state.selected.putChanged ?

                            <Group>
                                <Header mode="secondary">Игроки</Header>
                                {
                                    fetchedUsers && fetchedUsers.length > 0
                                        ? fetchedUsers.map((user, index) => <SimpleCell
                                            onClick={() => {
                                                this.setState({
                                                    showCardsModal: 'MODAL_CARDS_SHOW',
                                                    userClick: this.state.send.users[index],
                                                    fetchedUserClick: user,

                                                })
                                                this.props.setTapBarData(false)

                                            }}
                                            key={index}
                                            before={<Avatar size={40} src={user.photo_50}/>}
                                            // after={<IconButton><Icon28MessageOutline /></IconButton>}
                                        >{`${user.first_name} ${user.last_name}`}</SimpleCell>)
                                        : <SimpleCell
                                            disabled={true}
                                            before="Пользователи не найдены"
                                        ></SimpleCell>
                                }
                            </Group>

                            : null
                    }

                    {
                        <CurrentCards
                            sendCards={sendCards}
                            setCardSendActual={this.setCardSendActual}
                        />

                    }


                    <FormItem top="Сумма">
                        <Input
                            getRef={textInput}
                            pattern="[0-9]*"
                            type="number"
                            value={this.state.moneyCount}
                            onChange={event => this.moneyCountHandler(event)}
                            after={<IconButton hoverMode="opacity" aria-label="Очистить поле"
                                               onClick={clearField}><Icon16Clear/></IconButton>}/>
                    </FormItem>
                    <Div>
                        <Spacing size={20}/>
                        <Button
                            style={{width: '100%'}}
                            size="l"
                            onClick={(event) => {
                                this.transfer(event)
                                this.props.fetchData()
                            }}

                            disabled={(!this.validButton())}
                            mode="primary">
                            Перевести
                        </Button>
                    </Div>

                    <Spacing size={20}/>
                </FormLayout>
                <ModalRoot
                    activeModal={this.state.showCardsModal}
                    onClose={() => this.closeModal}
                >


                    <ModalCard
                        style={{marginBottom: '100px'}}
                        id={'MODAL_CARDS_SHOW'}
                        onClose={() => this.closeModal()}
                        // icon={<Icon56MoneyTransferOutline/>}
                        header={'Карты пользователя'}
                        subheader={
                            this.state.userClick ?
                                <UserData user={this.state.userClick} fetchedUser={this.state.fetchedUserClick}/> : null
                        }


                    >
                        <Spacing size={20}/>
                        { this.state.userClick ? <CurrentCards
                            sendCards={this.state.userClick.cards ? this.state.userClick.cards : null}
                            userPage={true}
                            setCardSendActual={this.setCardSendActual}
                            closeModal={this.closeModal}
                        /> : null }
                        <Spacing size={80}/>
                    </ModalCard>
                </ModalRoot>
            </>
        )
    }

}


const mapStateToProps = (state) => {
        return {
            user: state.user
            ,
            tapBar: state.tapBar
            ,
            transfer: state.transfer
        };
    }
;

const mapDispatchToProps =
    {
        setUserData,
        setUserAuth,
        setUserToken,
        setUserActive,
        setUserDebts,
        setTapBarData,
        setCardTransferData,
        setBankTransferType
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersTransfer));