import React, {Component} from 'react'
import classes from './Top.module.scss'
import {Avatar, Cell, Group, Panel, PanelHeader} from "@vkontakte/vkui";
import UserData from "../../components/UserData/UserData";
import {setUserActive, setUserAuth, setUserData, setUserToken} from "../../store/user/actions";
import {connect} from "react-redux";
import {withRouter} from "@happysanta/router";
import {setTopData} from "../../store/top/actions";
import {Icon16StarCircleFillYellow} from "@vkontakte/icons";
import {PAGE_USER, router} from "../../routers";

class Top extends Component {

    render() {
        const top = this.props.top.data

        return (
            <Panel id={this.props.id}>
                <PanelHeader>Топ игроков</PanelHeader>
                {top &&
                <Group>
                    { top.userIds.map((userId, index) => {
                        const user = top.users[userId]
                        return (<Cell
                            key={index}
                            before={user.photo_200 ? <Avatar size={48} src={user.photo_200}><div className={classes.PanelRatingPlace}>{index + 1}</div></Avatar> : null}
                            description={'Уровень: ' + user.level}
                            onClick={() => router.pushPage(PAGE_USER, {id: user.vk_id})}

                        >
                            {`${user.first_name} ${user.last_name}`} {user.premium ? <Icon16StarCircleFillYellow style={{display: "inline-flex"}} width={14} height={14}/> : null}

                        </Cell>)
                    })}
                </Group>}
                {this.props.snackMoneyBar}
            </Panel>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        top: state.top
    };
};

const mapDispatchToProps = {
    setUserData,
    setUserAuth,
    setUserToken,
    setUserActive,
    setTopData,

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Top));